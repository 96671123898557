import { getBrowserCookie } from 'lib/utils'
import { setBrowserCookie } from 'lib/utils'

const COOKIE_PRE_AUTH_REFERRAL_CODE = '__pre-auth-referral-code__'
const COOKIE_PRE_AUTH_REFERRAL_CODE_EXPIRY = 300 // 300 seconds = 5 minutes

// hook to store referral code in cookie if sign up coming from referral page.
// once social signin redirection is completed, referral code will be used in onboarding by reading from cookie
const useReferralInAuth = () => {
  const setPreAuthReferralCode = (referralCode?: string) => {
    if (!referralCode) return

    setBrowserCookie(COOKIE_PRE_AUTH_REFERRAL_CODE, referralCode, COOKIE_PRE_AUTH_REFERRAL_CODE_EXPIRY)
  }

  const getPreAuthReferralCode = () => {
    return getBrowserCookie(COOKIE_PRE_AUTH_REFERRAL_CODE)
  }

  const clearPreAuthReferralCode = () => {
    setBrowserCookie(COOKIE_PRE_AUTH_REFERRAL_CODE, '', 0)
  }

  return { setPreAuthReferralCode, getPreAuthReferralCode, clearPreAuthReferralCode }
}

export { useReferralInAuth }
