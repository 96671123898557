import React, { FC, ReactElement, ReactNode } from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { ActiveLink } from 'components/active-link'
import { SettingsAppBanner } from 'components/mobile-app-banner/settings-app-banner'

import useTranslation from 'lib/hooks/useTranslation'
import useAuth from 'lib/hooks/useAuth'

import { useGlobalContext } from 'lib/context/global-context'
import { buildPath, noop } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'
import {
  ACCOUNT_PROFILE_ROUTE,
  ACCOUNT_BOOKINGS_ROUTE,
  ACCOUNT_REWARDS_ROUTE,
  ACCOUNT_REVIEWS_ROUTE,
  WISHLIST_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  INVITE_FRIEND_ROUTE,
} from 'lib/constants/routes'

import s from './styles.module.scss'

interface SettingsProps {
  wrapperClassName?: string
  onItemClicked?: () => void
  trackEvent?: TrackEventType
  custom?: boolean
  showDownloadAppBanner?: boolean
}

interface SettingItemProps {
  isDivider?: boolean
  isHeader?: boolean
  as?: string
  onClick?: () => void
}

const Settings: FC<SettingsProps> & { Item: FC<SettingItemProps> } = ({
  wrapperClassName,
  onItemClicked,
  trackEvent = noop,
  children,
  showDownloadAppBanner,
  custom,
}) => {
  const { t, tsmart } = useTranslation(['bookings', 'product', 'user'])
  const { logout, user } = useAuth()
  const {
    isMobileView,
    globalArgs: { appTouchpointsData },
  } = useGlobalContext()
  const { isEnabled: isMobileAppTouchPointEnabled } = appTouchpointsData
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()
  const isReferralFeatureEnabled = showFeature('referralFeature')

  const handleItemClick = (itemName: any, handler?: () => void) => {
    if (!itemName) return

    trackEvent({ attributeId: itemName, attributeType: EVENTS.ATTRIBUTES_TYPE.MENU_ITEM })

    if (handler) handler()
    else if (onItemClicked) onItemClicked()
  }

  const settingsItems = [
    {
      label: t('t.wishlists', { ns: 'common' }),
      icon: <Icon name="heart" size="small" />,
      linkTo: WISHLIST_ROUTE,
      key: 'wishlist',
    },
    {
      label: t('bookings', { ns: 'booking' }),
      icon: <Icon name="booking-date-outline" size="small" />,
      linkTo: ACCOUNT_BOOKINGS_ROUTE,
      key: 'bookings',
    },
    {
      label: t('t.rewards', { ns: 'common' }),
      icon: <Icon name="circle-wavy-percent" size="small" viewBox="0 0 24 24" />,
      linkTo: ACCOUNT_REWARDS_ROUTE,
      key: 'rewards',
    },
    {
      label: t('review', { ns: 'product', count: 2 }),
      icon: <Icon name="chat-teardrop-text" size="small" />,
      linkTo: ACCOUNT_REVIEWS_ROUTE,
      query: { type: 'pending' },
      asPath: '/account/reviews/',
      key: 'reviews',
    },
    {
      label: t('profile', { ns: 'user' }),
      icon: <Icon name="user" size="small" />,
      linkTo: ACCOUNT_PROFILE_ROUTE,
      key: 'profile',
    },
    isReferralFeatureEnabled && user?.enableReferral
      ? {
          label: t('t.referAndEarn', { ns: 'common' }),
          icon: <Icon name="coin-vertical" size="small" />,
          linkTo: INVITE_FRIEND_ROUTE,
          key: 'referral',
          isNew: true,
        }
      : undefined,
    {
      label: t('t.settings', { ns: 'common' }),
      icon: <Icon name="settings" size="small" />,
      linkTo: ACCOUNT_SETTINGS_ROUTE,
      key: 'settings',
    },
    {
      label: tsmart('t.logOut', { ns: 'common' }),
      icon: <Icon name="sign-out" size="small" />,
      onClick: logout,
      key: 'logout',
    },
  ]

  const renderCustomItems = (nodes: any): any => {
    return React.Children.map(nodes, (child: ReactNode | ReactElement) => {
      if (!React.isValidElement(child)) return child

      const { isDivider, isHeader, onClick } = child.props

      if (isDivider) return <li className={s.divider} />

      // if Fragment is used, render each fragment item as list item
      if (child.type === React.Fragment) return renderCustomItems(child.props.children)

      return (
        <li
          className={cn(s.listItem, { [s.header]: isHeader })}
          onClick={() => {
            handleItemClick(child.key, onClick)
          }}
        >
          {/* since onclick event is passed to li element, child shouldnt have onclick events to avoid multiple calls */}

          {React.cloneElement(child, { onClick: undefined } as { onClick: undefined })}

          {isMobileView && !isDivider && !isHeader && (
            <span className={s.rightIcon}>
              <Icon name="caret-right" size="small" />
            </span>
          )}
        </li>
      )
    })
  }

  return (
    <ul className={cn(wrapperClassName, s.listItems)}>
      {custom
        ? renderCustomItems(children)
        : settingsItems.filter(Boolean).map((item) => {
            if (!item) return null
            const { label, icon, linkTo, query, asPath, key, isNew, onClick } = item
            const content = (
              <>
                {icon}
                <span>{label}</span>
              </>
            )
            return (
              <li
                key={label}
                className={s.listItem}
                onClick={() => {
                  handleItemClick(key, onClick)
                }}
              >
                {linkTo ? (
                  <ActiveLink
                    activeClassName="active"
                    href={query ? { pathname: linkTo, query } : buildPath(linkTo)}
                    {...(asPath && { as: asPath })}
                  >
                    <a>{content}</a>
                  </ActiveLink>
                ) : (
                  <span>{content}</span>
                )}
                {isNew && <span className={s.new}>{t('t.new', { ns: 'common' })}</span>}
              </li>
            )
          })}
      {showDownloadAppBanner && isMobileAppTouchPointEnabled && (
        <li className={s.appBanner}>
          <SettingsAppBanner trackEvent={trackEvent} />
        </li>
      )}
    </ul>
  )
}

Settings.Item = (props) => <>{props.children}</>
Settings.Item.displayName = 'SettingItem'

export default Settings
