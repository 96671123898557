// this is just wrapper component to make use of useAuth hook
import React, { useEffect, useMemo, useState } from 'react'
import { Trans } from 'next-i18next'

import { Toast } from 'components/toast'
import { ExternalLink } from 'components/external-link'

import useAuth from 'lib/hooks/useAuth'
import useCustomTranslation from 'lib/hooks/useTranslation'

import { isServer } from 'lib/utils'
import { useAppData } from 'lib/context/app-data-context'

import { MOBILE_APP_DOWNLOAD_LINK } from 'lib/constants'

const AccountCreateToast = () => {
  const [open, setOpen] = useState(false)
  const { accountCreateToast } = useAuth()
  const { t, tp } = useCustomTranslation()
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()
  const isReferralEnable = showFeature('referralFeature')

  // use effect state update is requied for making sure that the render will happen as expected
  // instead of just relying on accountCreateToast.isOpen boolean value
  useEffect(() => setOpen(accountCreateToast.isOpen), [accountCreateToast.isOpen])

  const { isReferral, isAccountExist } = accountCreateToast

  const message = useMemo(() => {
    if (isServer || !open || !isReferralEnable) return ''

    if (isAccountExist) return tp('msg.thisAccountAlreadyExistYouAreNowLoggedIn', { ns: 'auth' })

    if (!isReferral)
      return tp('msg.accountCreatedAndRewardsSavedToUseApplyThePromoOnCheckout', { ns: 'common' })

    return (
      <Trans
        i18nKey="msg.accountCreatedRewardsSavedToUseDownloadTheApp"
        ns="common"
        components={{
          a: <ExternalLink href={MOBILE_APP_DOWNLOAD_LINK} className="text-tertiary" />,
        }}
      />
    )
  }, [isReferral, isAccountExist, open, tp, isReferralEnable])

  return open ? (
    isReferralEnable ? (
      <Toast
        type="success"
        onClose={accountCreateToast.close}
        autoClose={10000}
        content={<div className="label-sm">{message}</div>}
      />
    ) : (
      <Toast
        type="success"
        onClose={accountCreateToast.close}
        message={`${t('traveller.msg.welcome')} ${tp('msg.yourBrandAccSuccessfullyCreatedAndVerifyied', {
          brand: t('brand', { ns: 'common' }),
        })}`}
      />
    )
  ) : null
}

export { AccountCreateToast }
