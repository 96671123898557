import cn from 'classnames'

import { Icon } from 'ui/icon'

import useAuth from 'lib/hooks/useAuth'

import { KF_TIER_COLOR_MAP } from 'lib/constants'

import s from './styles.module.scss'

const KfTier = () => {
  const { user } = useAuth()
  if (!user?.krisflyerTier) return null

  const variant = KF_TIER_COLOR_MAP[user.krisflyerTier]

  return (
    <div className={cn(s.kfTier, s[`variant-${variant}`])}>
      <Icon name="krisflyer-outline" size="mini" />
      <span>{user.krisflyerTier}</span>
    </div>
  )
}

export { KfTier }
