import { useCallback } from 'react'

import { setStorageItem } from 'lib/utils/web-storage'

import { NEW_ACCOUNT_CREATED_FLAG, NEW_USER_CONSENT_MODAL } from 'lib/constants'

import useAuth from './useAuth'

type NextActionType = 'marketing-consent' | 'signup-toast' | 'account-exist-toast'
type NextActionFnProps = {
  action: NextActionType
  isReferral?: boolean
  isPageReload?: boolean
}

const usePostSignupAction = () => {
  const { accountCreateToast, consentModal } = useAuth()

  const marketingConsentAction = useCallback(
    ({ isReferral, isPageReload }: { isReferral?: boolean; isPageReload?: boolean }) => {
      if (isPageReload) {
        setStorageItem(
          NEW_USER_CONSENT_MODAL,
          JSON.stringify({
            open: true,
            isReferral,
          })
        )
        return
      }

      consentModal.open({ isReferral })
    },
    [consentModal]
  )

  const signupToastAction = useCallback(
    ({ isReferral, isPageReload }: { isReferral?: boolean; isPageReload?: boolean }) => {
      if (isPageReload) {
        setStorageItem(NEW_ACCOUNT_CREATED_FLAG, JSON.stringify({ open: true, isReferral }))
        return
      }
      accountCreateToast.show({ isReferral })
    },
    [accountCreateToast]
  )

  const accountExistToastAction = useCallback(
    ({ isReferral, isPageReload }: { isReferral?: boolean; isPageReload?: boolean }) => {
      // only when from referral signup flow
      if (isReferral) {
        if (isPageReload) {
          setStorageItem(
            NEW_ACCOUNT_CREATED_FLAG,
            JSON.stringify({ open: true, isReferral, isAccountExist: true })
          )
          return
        }
        accountCreateToast.show({ isAccountExist: true })
      }
    },
    [accountCreateToast]
  )

  const nextAction = useCallback(
    ({ action, isReferral, isPageReload }: NextActionFnProps) => {
      if (action === 'marketing-consent') {
        marketingConsentAction({ isReferral, isPageReload })
      } else if (action === 'signup-toast') {
        signupToastAction({ isReferral, isPageReload })
      } else if (action === 'account-exist-toast') {
        accountExistToastAction({ isReferral, isPageReload })
      }
    },
    [marketingConsentAction, signupToastAction, accountExistToastAction]
  )

  return { nextAction }
}

export { usePostSignupAction }
