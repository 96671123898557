export const EVENTS = {
  SEARCH_RECENT: 'search_recent',
  SEARCH_TRENDING: 'search_trending',
  AUTO_SUGGEST: 'auto_suggest',
  AUTO_SUGGEST_SEARCH_INPUT: 'autosuggest_search',
  ACTIVITY_DATE: 'activity_date',
  ACTIVITY_TYPE: 'activity_type',
  ACTIVITY_TILE: 'activity_tile',
  ACTIVITY_VIEW_ALL: 'activity_view_all',
  ARTICLE_DETAIL: 'article_detail',
  ARTICLE_DESTINATION: 'article_destination',
  ARTICLE_COUNTRY: 'article_country',
  ARTICLE_HOME: 'article_home',
  BEST_PRICE_GUARANTEED: 'best_price_guaranteed',
  PARTIAL_PAYMENT: 'partial_payment',
  EARN_MILES_INFO_MODAL: 'earn_miles_info_modal',
  BOOKING_STATUS: 'booking_status',
  FIRST_BOOKING: 'first_booking',
  BOOKING_INTENT: 'booking_intent',
  BOOKING_DETAILS: 'booking_details_show',
  BOOKING: 'booking',
  BOOKING_TIMEOUT: 'booking_timeout',
  BOOKING_TIMEOUT_BACK_TO_PDP: 'booking_timeout_back_to_pdp',
  CANCEL_BOOKING: 'cancel_booking',
  KEEP_THIS_BOOKING: 'keep_this_booking',
  CATEGORY: 'category',
  CATEGORY_TILE: 'category_tile',
  CONTINUE_BROWSING: 'continue_browsing',
  CONTINUE_WITHOUT_PROMOCODE: 'continue_without_promocode',
  CONTINUE_WITHOUT_PROMOCODE_CLOSE: 'continue_without_promocode_close',
  COUNTRY: 'country',
  COLLECTION: 'collection',
  CLEAR_ALL: 'clear_all',
  CTA_FROM_ARTICLE: 'cta_from_article',
  BREADCRUMBS: 'breadcrumbs',
  EMAIL_TYPO: 'email_typo',
  EXCLUSIVE_BENEFITS: 'exclusive_benefits',
  FILTER_SORT: 'filter_sort',
  FILTER_MODAL_CLOSE: 'filter_modal_close',
  FOOTER_ARTICLE: 'footer_article',
  FOOTER_LOGO: 'footer_logo',
  FOOTER_LANGUAGE: 'footer_language',
  FOOTER_CURRENCY: 'footer_currency',
  FOOTER_DESTINATION: 'footer_destination',
  FOOTER_HELP: 'footer_help',
  FOOTER_DOWNLOAD_APP: 'footer_download_app',
  FOOTER: 'footer',
  GUEST_SIGNUP: 'guest_signup',
  HEADER_LOGO: 'header_logo',
  HEADER_BLOG_LOGO: 'header_blog_logo',
  HEADER_HELP: 'header_help',
  HEADER_MENU: 'header_menu',
  HEADER_WISHLIST: 'header_wishlist',
  ITINERARY: 'itinerary',
  ITINERARY_TABS: 'itinerary_tabs',
  INVITE_FRIEND: 'invite_friend',
  EDIT_ITINERARY: 'edit_itinerary',
  EDIT_ITINERARY_SUBMIT: 'edit_itinerary_submit',
  CREATE_ITINERARY: 'create_itinerary',
  CREATE_ITINERARY_SUBMIT: 'create_itinerary_submit',
  TRIP_PLANNER: 'trip_planner',
  MAP: 'map',
  MARKETING_BANNER: 'marketing_banner',
  MOBILE_APP: 'mobile_app',
  ONGOING_DEALS_MOBILE_APP: 'ongoing_deals_mobile_app',
  DESKTOP_HEADER_MOBILE_APP: 'desktop_header_mobile_app',
  MOBILE_MENU_MOBILE_APP: 'mobile_menu_mobile_app',
  SETTINGS_MOBILE_APP: 'settings_mobile_app',
  BOOKING_MOBILE_APP: 'booking_mobile_app',
  PRODUCT_MOBILE_APP: 'product_mobile_app',
  CLAIM_REWARDS: 'claim_rewards',
  PROMO: 'promo',
  EXIT: 'exit',
  ENTER: 'enter',
  TTD: 'ttd',
  PROMO_DISCOUNT: 'promo_discount',
  PROMO_CLOSE: 'promo_close',
  LATEST_ARTICLES: 'latest_articles',
  MARKETING_BANNER_CLOSE: 'marketing_banner_close',
  MARKETING_BANNER_TNC: 'marketing_banner_tnc',
  MARKETING_CONSENT: 'marketing_consent',
  MARKETING_CONSENT_ACCEPTED: 'marketing_consent_accepted',
  MARKETING_CONSENT_SKIPPED: 'marketing_consent_skipped',
  MINI_SIGN_UP_BANNER: 'mini_sign_up_banner',
  MY_BOOKINGS: 'my_bookings',
  MY_DETAILS: 'my_details',
  PROMOCODE: 'promo_code',
  PNR_PROMO: 'pnr_promo',
  PROMOCODE_APPLY: 'promo_code_apply',
  PROMOCODE_REMOVE: 'promo_code_remove',
  PROMOCODE_USE: 'promo_code_use',
  PROMOCODE_LEARN_MORE: 'promo_learn_more',
  CUSTOMER_NEXT_PROMO: 'customer_next_promo',
  PRICE: 'price',
  RETRY_BOOKING: 'retry_booking',
  REVIEW_SUMMARY: 'review_summary',
  REVIEW_TAGS: 'review_tags',
  REFERRAL: 'referral',
  SETTINGS_BOOKINGS: 'settings_bookings',
  SETTINGS_LOGOUT: 'settings_logout',
  SETTINGS_PROFILE: 'settings_profile',
  SETTINGS_SETTINGS: 'settings_settings',
  SHARE: 'share',
  SHOW_ACTIVITIES: 'show_activities',
  STEALS_UNDER_50_SEE_ALL: 'steals_under_50_see_all',
  STEALS_UNDER_50: 'steals_under_50',
  ARTICLE_LISTING_ALL: 'article_listing_all',
  SIGNUP_NOW_BY_PROMO: 'signup_through_promo',
  TOP_ACTIVITY_PRODUCTS: 'top_activity_products',
  COPY_PROMO_CODE: 'copy_promo_code',
  POPULAR_CATEGORIES: 'popular_categories',
  POPULAR_PRODUCTS: 'poplar_products',
  POPULAR_ARTICLES: 'popular_articles',
  FROM_WISHLIST_LOGIN: 'from_wishlist_page_login',
  FROM_WISHLIST_SIGNUP: 'from_wishlist_page_signup',
  FROM_SETTINGS_LOGIN: 'from_settings_page_login',
  FROM_SETTINGS_SIGNUP: 'from_settings_page_signup',
  PRODUCT_LISTING: 'product_listing',
  PRODUCT_DETAIL: 'product_detail',
  PRODUCT_OPTION_UNIT_QUANTITY: 'product_option_unit_quantity',
  PRODUCT_OPTION_PACKAGE_SPECS: 'product_option_package_specs',
  PRODUCT_OPTION_PACKAGE_SPECS_ITEM: 'product_option_package_specs_item',
  PRODUCT_SECTION: 'product_section',
  PRODUCT_SELECT: 'product_select',
  PRODUCT_OPTION_READ_MORE: 'product_option_read_more',
  PRODUCT_OPTION_AVAILABILITY: 'product_option_availability',
  PRODUCT_OPTION: 'product_option',
  PRODUCT_OPTION_GROUP: 'product_option_group',
  PRODUCT_CHECKOUT: 'product_checkout',
  PRODUCT_REVIEW: 'product_review',
  PRODUCT_REVIEW_SHOW_ALL: 'product_review_show_all',
  PRODUCT_DETAIL_TABS: 'product_detail_tabs',
  SEE_ALL: 'see_all',
  PRODUCT: 'product',
  PICKS_OF_THE_MOMENT: 'picks_of_the_moment',
  DESTINATION: 'destination',
  DESTINATIONS_SHOW_ALL: 'destinations_show_all',
  MEDIA_GALLERY: 'media_gallery',
  MEDIA_SLIDER_LEFT: 'media_slider_left',
  MEDIA_SLIDER_RIGHT: 'media_slider_right',
  SLIDER: 'content_slider',
  SLIDER_EXPAND: 'content_slider_expand',
  SLIDER_EXPLORE_ALL: 'slider_card_explore_all',
  MEDIA_GALLERY_PREVIEW: 'media_gallery_preview',
  REVIEW_COUNT: 'review_count',
  RELATED_PRODUCTS: 'related_products',
  COMBO_DEAL_PRODUCTS: 'combo_deal_products',
  COMBO_DEAL_PRODUCTS_VIEW_MORE: 'combo_deal_products_view_more',
  YMAL_PRODUCTS: 'yaml_products',
  ARTICLE_LISTING: 'article_listing',
  RECENTLY_VIEWED: 'recently_viewed',
  WHY_BOOK_WITH_PELAGO: 'why_book_with_pelago',
  HEADER: 'header',
  ARTICLE: 'article',
  FEATURE: 'feature',
  PAYMENT: 'payment',
  DETAILS: 'details',
  CURRENCY: 'currency',
  LANGUAGE: 'language',
  WISHLIST: 'wishlist',
  WISHLIST_ITEMS: 'wishlist_items',
  WISHLIST_DELETE: 'wishlist_delete',
  WISHLIST_RENAME: 'wishlist_rename',
  SHARED_LIST: 'shared_list',
  BACK_TO_WISHLISTS: 'back_to_wishlists',
  NEW_WISHLIST: 'new_wishlist',
  GIVE_SUGGESTIONS: 'give_suggestions',
  CREATE_WISHLIST: 'create_wishlist',
  SAVE_WISHLIST: 'save_wishlist',
  WISHLIST_SETTINGS: 'wishlist_settings',
  CONTACT_US: 'contact_us',
  FAQ: 'faq',
  SIGNUP: 'signup',
  TRY_AGAIN: 'try_again',
  GOOGLE_SIGNUP: 'google_signup',
  FACEBOOK_SIGNUP: 'facebook_signup',
  LOGIN: 'login',
  GOOGLE_LOGIN: 'google_login',
  FACEBOOK_LOGIN: 'facebook_login',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
  WELCOME: 'welcome',
  WELCOME_CONTINUE: 'welcome_continue',
  FIND_MORE_ACTIVITY_TO_DO: 'find_more_activities_to_do',
  MARKETING_EMAILS: 'marketing_emails',
  MARKETING_WA_CONSENT: 'marketing_wa_consent',
  REDEEM_TICKETS: 'redeem_tickets',
  REDEEM_TICKETS_STATUS: 'redeem_tickets_status',
  REMINDER_EMAILS: 'reminder_emails',
  REMINDER_WA_CONSENT: 'reminder_wa_consent',
  PASSWORD_CHANGE: 'password_change',
  USER_SETTINGS: 'user_settings',
  USER_REVIEW: 'user_review',
  USER_REWARDS: 'user_rewards',
  WRITE_REVIEW: 'write_review',
  REVIEW_SUBMIT: 'review_submit',
  DOWLOAD_VOUCHER: 'download_voucher',
  VIEW_VOUCHER: 'view_voucher',
  USER_PROFILE: 'user_profile',
  USER_LOGGED_IN: 'user_logged_in',
  USER_SIGNED_UP: 'user_signed_up',
  USER_LOCK_OUT: 'user_log_in_lock_out',
  PROFILE_EDIT: 'profile_edit',
  BOOKING_VIEW_MORE: 'user_booking_view_more',
  USER_BOOKING: 'user_booking',
  USER_BOOKING_FAQ: 'user_booking_faq',
  SUBMIT_REQUEST: 'submit_request',
  BACK_TO_BOOKING: 'back_to_booking',
  USER_BOOKING_PRODUCT: 'user_booking_product',
  SEARCH_LISTING: 'search_listing',
  SLICK_SLIDER: 'slick_slider',
  REVIEW_MODAL: 'review_modal',
  CUSTOMER_REVIEW: 'customer_review',
  TRANSLATION: 'translation',
  TRAVELLING_DURING_COVID: 'travelling_during_covid',
  TRAVELLER_TYPE: 'traveller_type',
  RESEND_PASSWORD_RESET: 'resend_password_reset',
  EXPLORE_ALL: 'explore_all',
  PENDING_REVIEW: 'pending_review',
  FILTER_PRODUCT: 'filter_product',
  HOME: 'home',
  ACTIVITY: 'activity',
  SEARCH: 'search',
  SUPPORT: 'support',
  PRIORITY_SUPPORT: 'priority_support',
  SUPPORT_HELP: 'support_help',
  HELPCENTER: 'helpcenter',
  ADD: 'add',
  REMOVE: 'remove',
  PAYMENT_METHOD: 'payment_method',
  KRISFLYER_LOGIN: 'krisflyer_login',
  KRISFLYER_EARN: 'krisflyer_earn',
  KRISFLYER_PRIORITY_SUPPORT: 'krisflyer_priority_support',
  AUTOSUGGEST_SEARCH_SHOW_ALL: 'autosuggest_search_show_all',
  SHOW_MAP: 'show_map',
  SORTING: 'sorting',
  PHONE_ENTRY: 'phone_entry',
  PHONE_VERIFICATION: 'phone_verification',
  PHONE_VERIFICATION_CLOSE: 'phone_verification_close',
  PRESETS: 'presets',
  RESEND_OTP: 'resend_otp',
  ONGOING_DEALS: 'ongoing_deals',
  TOP_PRODUCT: 'top_product',
  STATUS: {
    SUCCESS: 'success',
    FAILED: 'failed',
  },
  OTHER: 'other',
  REDEEM_STEP_SUBMIT: 'redeem_step_submit',
  REDEEM_STEP_EDIT: 'redeem_step_edit',
  REDEEM_STEPS: 'redeem_steps',
  ADD_REDEMPTION_CODE: 'add_redemption_code',
  REMOVE_REDEMPTION_CODE: 'remove_redemption_code',
  SAVE_PAYMENT_CARD: 'save_payment_card',
  SEASONAL_CAMPAIGN: {
    BANNER: 'campaign_banner',
    MODAL: 'campaign_modal',
    MODAL_CLOSE: 'campaign_modal_close',
  },
  TYPE: {
    EXPOSURE: 'exposure',
    CLICK: 'click',
    FOCUS: 'focus',
    BLUR: 'blur',
  },
  ATTRIBUTES_TYPE: {
    PAGE: 'page',
    BANNER: 'banner',
    BUTTON: 'button',
    CHIPS: 'chips',
    LINK: 'link',
    TOGGLE: 'toggle',
    INPUT: 'input',
    MENU_ITEM: 'menu_item',
    FORM: 'form',
    CARD: 'card',
    FILTER: 'filter',
    BUCKET: 'bucket',
    RESULT: 'result',
    LIST: 'list',
    OPTION: 'option',
    RIBON: 'ribon',
    ICON: 'icon',
    IMAGE: 'image',
    CAROUSEL: 'carousel',
    ERROR: 'error',
    SUCCESS: 'success',
    SLIDER: 'slider',
    TAB: 'tab',
    DATEPICKER: 'date_picker',
    PAGINATION: 'pagination',
    SECTION: 'section',
    POPUP: 'popup',
    CHATBOT: 'chatbot',
  },
  EXPERIMENT: {
    PDP_DESIGN: 'pdp_redesign',
    PDP_STRIKE_OFF: 'pdp_strike_off',
    TTD_PROMO: 'ttd_promo',
    DP_FEATURE_CARD: 'dp_feature_card',
    TOP_PICK_PINNED_SEARCH: 'top_pick_pinned_search',
    COLUMN_VIEW_AB: 'column_view_ab',
    DESTINATION_AUTO_SUGGEST: 'destination_auto_suggest',
    QUERY_SUGGEST: 'query_suggest',
    MINI_SIGNUP_BANNER: 'mini_signup_banner',
  },
  EXPERIMENT_GROUP: {
    TEST: 'test',
    CONTROL: 'control',
    TREATMENT_1: 'treatment_1',
    TREATMENT_2: 'treatment_2',
  },
}
