import { ParsedUrlQuery } from 'querystring'

import { GetServerSidePropsContext, NextPageContext } from 'next'

import { getHttpHeaders } from 'lib/apollo-client'

import { LOGIN_ROUTE } from 'lib/constants/routes'
import {
  ACTIVITY_LOG,
  COOKIES_DS_USER_ID,
  COOKIES_OLD_DS_USER_ID,
  COOKIES_RECENT_PDP_VISIT,
  COOKIES_RECENT_PDP_VISIT_EXPIRY,
  COOKIES_REFRESH_TOKEN,
  COOKIES_TOKEN,
  LOCAL_STORAGE_KEY,
} from 'lib/constants'

import { USER_QUERY_FETCH } from 'gql'
import { REFRESH_TOKEN_QUERY } from 'gql/auth'

import {
  buildPath,
  generateDsUserId,
  getBrowserCookie,
  getErrorInfo,
  isPrivateRoute,
  logError,
  serialiseCookie,
  setBrowserCookie,
} from '.'
import { getStorageItem, setStorageItem } from './web-storage'

export const getDsUserId = (isOld?: boolean) =>
  getBrowserCookie(isOld ? COOKIES_OLD_DS_USER_ID : COOKIES_DS_USER_ID)

export const setDsUserId = (id: string, isOld?: boolean) =>
  setBrowserCookie(isOld ? COOKIES_OLD_DS_USER_ID : COOKIES_DS_USER_ID, id)

export const getUserStorageData = () => {
  let data
  const userData = getStorageItem(LOCAL_STORAGE_KEY)
  if (userData) {
    try {
      data = JSON.parse(userData)
    } catch (e) {
      // storage data is tampered, reset it and log error
      setStorageItem(LOCAL_STORAGE_KEY, null)
      logError(e)
    }
  }
  return data
}

export const getToken = () => getBrowserCookie(COOKIES_TOKEN) || ''

export const setToken = (token = '', maxAge?: number) => {
  setBrowserCookie(COOKIES_TOKEN, token, maxAge)
}

export const setUserStorageData = (user: Customer | null) => {
  setStorageItem(LOCAL_STORAGE_KEY, JSON.stringify({ user }))
}

export const setRefreshToken = (token: string, maxAge?: number) => {
  setBrowserCookie(COOKIES_REFRESH_TOKEN, token, maxAge)
}

export const clearUserActivityLog = () => {
  setStorageItem(ACTIVITY_LOG, JSON.stringify({}))
  setBrowserCookie(COOKIES_RECENT_PDP_VISIT, 'false', COOKIES_RECENT_PDP_VISIT_EXPIRY)
}

export const clearToken = () => {
  setToken('', 0)
  setRefreshToken('', 0)
}

export const isLoggedIn = () => Boolean(getToken())
export interface NextPageContextEnhanced extends NextPageContext {
  resolvedUrl?: string
}

export const forceLogout = (
  context?: GetServerSidePropsContext | NextPageContextEnhanced,
  config?: { pathname?: string; query?: ParsedUrlQuery }
) => {
  if (context) {
    const requestedUrl = context.resolvedUrl || context.req?.url
    const cookiesToUnset = [
      serialiseCookie(COOKIES_TOKEN, '', 0),
      serialiseCookie(COOKIES_REFRESH_TOKEN, '', 0),
    ]
    context.res?.setHeader('Set-Cookie', cookiesToUnset)

    // write head only works with production build
    if (isPrivateRoute(requestedUrl) && process.env.NODE_ENV === 'production') {
      context.res?.writeHead(302, { Location: buildPath(LOGIN_ROUTE, {}, { redirectUrl: requestedUrl }) })
      context.res?.end()
    }
    return
  }
  setBrowserCookie(COOKIES_REFRESH_TOKEN, '', 0)
  setBrowserCookie(COOKIES_TOKEN, '', 0)
  setUserStorageData(null)
  clearUserActivityLog()
  // clear user attached dsUserId and generate new one as guest
  setDsUserId(generateDsUserId({ cookies: {} }) || '')

  if (config?.pathname && isPrivateRoute(config?.pathname)) {
    const redirectUrl = config?.pathname
    window.location.replace(buildPath(LOGIN_ROUTE, {}, { redirectUrl, ...config?.query }))
  } else {
    window.location.reload()
  }
}

// why fetch instead of apollo client?
// apollo client built in a way to call the same query after refreshing the token if the user token has already expired (so "me" query will be called twice)
// the purpose of "me" query here is to identify if the current token is expired, you dont have to call it again after refreshing the token, hence the usage of fetch
// if the refresh token request is failed, nothing should happen, continue with the rest of the flow
export const refreshTokenIfRequired = async (locale: string) => {
  const token = getToken()

  if (token) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const response = await fetch(process.env.NEXT_PUBLIC_GRAPHQL!, {
        method: 'POST',
        headers: getHttpHeaders(undefined, { locale, operationName: USER_QUERY_FETCH.name }),
        body: JSON.stringify(USER_QUERY_FETCH.query),
      })
      const data = (await response.json()).data
      const error = getErrorInfo(data[USER_QUERY_FETCH.name])
      const user = data[USER_QUERY_FETCH.name]

      // token is available in cookie, but "me" query return 422 or null user => try getting a new token
      if (error?.code === 422 || !user) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const tokenResponse = await fetch(process.env.NEXT_PUBLIC_GRAPHQL!, {
          method: 'POST',
          headers: getHttpHeaders(undefined, {
            operationName: REFRESH_TOKEN_QUERY.name,
            locale,
          }),
          body: JSON.stringify(REFRESH_TOKEN_QUERY.query),
        })
        const tokenData = (await tokenResponse.json()).data
        const accessToken = tokenData[REFRESH_TOKEN_QUERY.name].accessToken

        // if access token is available, set it as new token. or else continue with gssp call
        if (accessToken) setToken(accessToken)
      }
      if (error?.code === 401) forceLogout()
    } catch (error) {
      logError(error)
    }
  }
}
