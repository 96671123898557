import { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import {
  ProductOption as SearchAutocompleteProductOption,
  DestinationOption as SearchAutocompleteDestinationOption,
} from 'components/search-autocomplete'

import { ActivityLog } from 'lib/context/app-data-context'

import { APOLLO_CLIENT_VERSION } from 'lib/constants'

import { GET_USER_SEARCH_HISTORY } from 'gql/queries/search'

import useAuth from './useAuth'

type QueryApiType = { query: string; lastVisitedTimestamp: string }
type ProductApiType = SearchAutocompleteProductOption & { lastVisitedTimestamp: string }
type DestinationApiType = SearchAutocompleteDestinationOption & { lastVisitedTimestamp: string }

type AddActivityItemArgsInBulk = {
  queries: [QueryApiType]
  products: [ProductApiType]
  destinations: [DestinationApiType]
}

type AddActivityItemInBulkFn = (args: AddActivityItemArgsInBulk) => void

type UserActivity = Omit<ActivityLog, 'destinationCountries'>

const useUserHistorySyncup = ({ activityLog }: { activityLog: ActivityLog }) => {
  const { isLoggedIn } = useAuth()

  const [getUserSearchHistory] = useLazyQuery(GET_USER_SEARCH_HISTORY.query)
  const [userActivity, setUserActivity] = useState<UserActivity | undefined>()

  const addToActivityLogInBulk: AddActivityItemInBulkFn = useCallback(
    ({ queries = [], products = [], destinations = [] }) => {
      // js only accepts milliseconds
      const secondsToMilliSeconds = (seconds: number | string) => Number(seconds) * 1000

      // clear the keyword if already existed
      let uniqueKeywords = activityLog.keywords?.filter?.(
        (item) => !queries.some((q: QueryApiType) => q.query === item.text)
      )
      // clear the products if already existed
      let uniqueProducts = activityLog.products?.filter?.(
        (item) => !products.some((p: ProductApiType) => p.productId === item.info?.productId)
      )
      // clear the destinations if already existed
      let uniqueDestinations = activityLog.destinations?.filter?.(
        (item) => !destinations.some((d: DestinationApiType) => d.destinationId === item.info?.destinationId)
      )

      if (queries?.length) {
        queries.forEach((k: QueryApiType) => {
          uniqueKeywords.push({
            text: k.query,
            created: new Date(secondsToMilliSeconds(k.lastVisitedTimestamp)),
          })
        })
        uniqueKeywords = uniqueKeywords
          .filter(Boolean)
          .sort(
            (a: Record<string, any>, b: Record<string, any>) =>
              new Date(Number(b.created)).getTime() - new Date(Number(a.created)).getTime()
          )
      }

      if (products?.length) {
        products.forEach((p: ProductApiType) => {
          uniqueProducts.push({ info: p, created: new Date(secondsToMilliSeconds(p.lastVisitedTimestamp)) })
        })
        uniqueProducts = uniqueProducts
          .filter(Boolean)
          .sort(
            (a: Record<string, any>, b: Record<string, any>) =>
              new Date(Number(b.created)).getTime() - new Date(Number(a.created)).getTime()
          )
      }

      if (destinations?.length) {
        destinations.forEach((d: DestinationApiType) => {
          uniqueDestinations.push({
            info: { ...d, destinationCode: `${d.code}` },
            created: new Date(secondsToMilliSeconds(d.lastVisitedTimestamp)),
          })
        })
        uniqueDestinations = uniqueDestinations
          .filter(Boolean)
          .sort(
            (a: Record<string, any>, b: Record<string, any>) =>
              new Date(Number(b.created)).getTime() - new Date(Number(a.created)).getTime()
          )
      }

      const newLog = {
        destinations: uniqueDestinations.slice(0, 3),
        products: uniqueProducts.slice(0, 8),
        keywords: uniqueKeywords.slice(0, 3),
      }
      setUserActivity(newLog)
    },
    [activityLog]
  )

  useEffect(() => {
    if (!isLoggedIn) return

    const fetchUserSearchHistory = async () => {
      const { data } = await getUserSearchHistory({
        variables: {
          input: { page: 1, pageSize: 10 },
        },
        context: { version: APOLLO_CLIENT_VERSION.SEARCH },
      })

      const { recentlyViewed } = data || {}
      const { products, destinations, queries } = recentlyViewed || {}
      addToActivityLogInBulk({ products, destinations, queries })
    }

    fetchUserSearchHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, getUserSearchHistory])

  return { userActivity }
}

export { useUserHistorySyncup }
