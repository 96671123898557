import React, { useEffect } from 'react'
import cn from 'classnames'
import { useMutation } from '@apollo/client'

import { Icon } from 'ui/icon'
import { Button } from 'ui/button'

import { Modal } from 'components/responsive-modal'
import { ExternalLink } from 'components/external-link'

import useTranslation from 'lib/hooks/useTranslation'
import useAuth from 'lib/hooks/useAuth'
import { usePostSignupAction } from 'lib/hooks/usePostSignupAction'

import { useAppData } from 'lib/context/app-data-context'
import { logError, getError } from 'lib/utils'

import { PRIVACY_POLICY_LINK } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import { PROFILE_MUTATION } from 'gql/mutations/profile'
import Gift from 'brand-assets/illustrations/icons/gift.svg'

import s from './styles.module.scss'

const MarketingConsentModal = () => {
  const { t, tp, tsmart, sp } = useTranslation()
  const { consentModal, handleUserResponse, accountCreateToast } = useAuth()
  const {
    trackEvent,
    partnerFeatureControl: { showFeature },
  } = useAppData()
  const [editProfile, { loading }] = useMutation(PROFILE_MUTATION.mutation)
  const { nextAction } = usePostSignupAction()
  const isReferralFeatureEnabled = showFeature('referralFeature')

  useEffect(() => {
    if (consentModal.isOpen) {
      trackEvent?.current?.({
        attributeId: EVENTS.MARKETING_CONSENT,
        attributeType: EVENTS.ATTRIBUTES_TYPE.POPUP,
        eventType: EVENTS.TYPE.EXPOSURE,
      })
    }
  }, [consentModal.isOpen, trackEvent])

  const enableMarketingUpdates = async () => {
    try {
      trackEvent?.current?.({
        attributeId: EVENTS.MARKETING_CONSENT_ACCEPTED,
        attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
      })

      const { data } = await editProfile({
        variables: { details: { marketingEmail: true, waMarketingConsent: true } },
      })
      const response = data?.[PROFILE_MUTATION.mutationName] || {}

      const error = getError(response)
      if (error?.code) {
        logError(new Error(error?.errorMessage))
        return
      }
      handleUserResponse({ customer: response })
    } catch (error) {
      logError(error)
    } finally {
      consentModal.close()
      if (isReferralFeatureEnabled) {
        nextAction({ action: 'signup-toast', isReferral: consentModal.isReferral, isPageReload: false })
      } else {
        accountCreateToast.show({ isReferral: false })
      }
    }
  }

  const handleClose = () => {
    trackEvent?.current?.({
      attributeId: EVENTS.MARKETING_CONSENT_SKIPPED,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
    })
    consentModal.close()
    if (isReferralFeatureEnabled) {
      nextAction({ action: 'signup-toast', isReferral: consentModal.isReferral, isPageReload: false })
    } else {
      accountCreateToast.show({ isReferral: false })
    }
  }

  return (
    <Modal open={consentModal.isOpen} hideCloseButton>
      <div className={cn(s.card, { [s.loading]: loading })}>
        <div className={s.banner}>
          <h3 className={s.title}>{tp('msg.joinListForLatestAndGreatestDeals', { ns: 'common' })}</h3>
          <div className={s.gift}>
            <Gift />
          </div>
        </div>
        <div className={cn(s.content)}>
          <ul className={s.benifits}>
            <li>
              <Icon name="envelope" />
              <p>{tp('msg.beTheFirstToReceivePromotionsIncludingFromPartners', { ns: 'common' })}</p>
            </li>
            <li>
              <Icon name="gift" />
              <p>{tp('msg.getAnotherDiscountOffCode', { ns: 'common', discount: 10 })}</p>
            </li>
          </ul>
          <Button variant="primary" size="large" fluid onClick={enableMarketingUpdates}>
            {t('exclm.imIn', { ns: 'common' })}
          </Button>
          <div className="flex-center mt-4">
            <Button variant="link-tertiary" size="large" onClick={handleClose}>
              {t('action.skip', { ns: 'common' })}
            </Button>
          </div>

          <p className={s.unsubscribeText}>
            {tp('msg.changeYourPreferencesAnytime', { ns: 'common' })}
            {sp}
            <ExternalLink className="tertiary-link" href={PRIVACY_POLICY_LINK}>
              {tsmart('t.privacyPolicy', { ns: 'common' })}
            </ExternalLink>
          </p>
        </div>
      </div>
    </Modal>
  )
}

export { MarketingConsentModal }
