import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import { BOOKING_STATUS_ROUTE } from 'lib/constants/routes'
import { BOOKING_STATUS_LITE_ROUTE } from 'lib/constants/routes'

import { USER_QUERY } from '../../gql/queries/profile'
import useRouteMatch from './useRouteMatch'

/**
 * Custom hook to refresh user information after authentication to sync latest user profile data e.g. referral active/inactive status
 *
 * This hook sets up a delayed query to fetch updated user data after login.
 * It uses a timeout to prevent race conditions with auth state changes and
 * wait for other important operations to complete.
 * The query only runs when the user is logged in.
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   useRefreshUserInfo() // Call the hook to set up auto-refresh
 *   return <div>...</div>
 * }
 * ```
 *
 * @returns {void} This hook doesn't return anything, it just sets up the refresh mechanism
 *
 * @see {@link USER_QUERY} for the GraphQL query used to fetch user data
 * @see {@link useAuth} for authentication state management
 */
function useRefreshUserInfo({ skip }: { skip?: boolean }) {
  const [refresh, { data }] = useLazyQuery(USER_QUERY.query)
  const isBookingStatusRoute = Boolean(useRouteMatch(BOOKING_STATUS_ROUTE))
  const isBookingStatusLieRoute = Boolean(useRouteMatch(BOOKING_STATUS_LITE_ROUTE))
  const isUserBookedProduct = isBookingStatusRoute || isBookingStatusLieRoute

  useEffect(() => {
    if (skip) return

    let timeoutId: NodeJS.Timeout | undefined

    if (!skip || isUserBookedProduct) {
      // Delay refresh to avoid race conditions with auth state
      timeoutId = setTimeout(() => {
        refresh()
      }, 1300)
    } else if (timeoutId) {
      clearTimeout(timeoutId)
    }

    // Cleanup timeout on unmount or auth state change
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [skip, isUserBookedProduct, refresh])

  return { refreshedUserInfo: data?.[USER_QUERY.queryName] }
}

export default useRefreshUserInfo
