import { gql } from '@apollo/client'

import { PelagoError } from './fragments'
import {
  COUNTRIES_CONDITIONAL_QUERY_BODY,
  DESTINATION_CONDITIONAL_QUERY_BODY,
  PRODUCT_FILTERS_BODY,
} from './queries'
import { DYNAMIC_COMPONENTS_DATA_QUERY_BODY } from './queries/common'

export * from './queries'
export * from './mutations'
export * from './mapper'

export const GLOBAL_ARGS_QUERY_BODY = `
  tags {
    tagCode
    tagId
    tagLabel
    tagType
    tagIcon
    tagShortLabel
  }
  categories {
    categoryId
    code
    parentId
    value
  }
  currencies {
    countryId
    currencyId
    currencyName
    currencySymbol
  }
  constants
  locales {
    currency
    languageLabel
    languageName
    localeLabel
  }
  rewardsBanner
  whitelabel {
    imageUrl
    partnerId
    partnerName
    partnerWebsiteLink
  }
  kfMilesRate {
    kfMilesEarnRateSgd
    kfMilesBurnRateSgd
  }
  appTouchpointsData {
    isEnabled
    header
    subHeader
  }
  referralData {
    isEnabled
  }
`
export const GLOBAL_ARGS_QUERY = gql`
  query globalArgs {
    ${GLOBAL_ARGS_QUERY_BODY}
  }
`

export const CAMPAIGN_QUERY = {
  query: gql`
    query campaign($campaignId: String, $campaignSource: String, $preview: Boolean) {
      campaign(campaignId: $campaignId, campaignSource: $campaignSource, preview: $preview) {
        ... on Campaign {
          campaignId
          campaignName
          campaignType
          startDate
          endDate
          isActive
          campaignMetaData
          mediaData
          dateModified
          content
          pages
        }
        ${PelagoError}
      }
    }
  `,
  queryName: 'campaign',
}

export const SEARCH_PAGE_QUERY = {
  query: gql`
    query globalArgsProductFiltersAndCollection($dynamicComponentsInput: DynamicComponentDetailsInput!, $destinationId: String!,  $hasDestination: Boolean!, $hasCountry: Boolean!) {
      ${GLOBAL_ARGS_QUERY_BODY}
      ${PRODUCT_FILTERS_BODY}
      ${COUNTRIES_CONDITIONAL_QUERY_BODY}
      ${DESTINATION_CONDITIONAL_QUERY_BODY}
      ${DYNAMIC_COMPONENTS_DATA_QUERY_BODY}
    }
  `,
  queryName: {
    tag: 'tags',
    categories: 'categories',
    countries: 'countries',
    destination: 'destination',
    productFilters: 'productFilters',
    dynamicComponentsData: 'dynamicComponentsData',
  },
}

export const TRACK_CONTEXT_QUERY = gql`
  query trackContext {
    trackContext {
      ...on PelagoOutput {
        output
      }
      ${PelagoError}
    }
  }
`
