import { Icon } from 'ui/icon'

import { KfTier } from 'components/kf-tier'
import { Link } from 'components/link'
import { ExternalLink } from 'components/external-link'

import useTranslation from 'lib/hooks/useTranslation'
import useAuth from 'lib/hooks/useAuth'

import { useGlobalContext } from 'lib/context/global-context'
import { useAppData } from 'lib/context/app-data-context'
import { buildPath, getIsSilverOrGoldKfTier } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'
import { HELPCENTER_LINK } from 'lib/constants'
import { PRIORITY_SUPPORT_ROUTE } from 'lib/constants/routes'

import s from './styles.module.scss'

const SupportDropdown = () => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const { trackEvent } = useAppData()
  const { yellowAi } = useGlobalContext()

  const handleVirtualAssistantClick = () => {
    yellowAi.showAndToggle()

    trackEvent.current?.({
      attributeId: EVENTS.HEADER,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CHATBOT,
    })
  }

  const handleHelpCenterClick = () => {
    trackEvent.current?.({
      link: HELPCENTER_LINK,
      attributeId: EVENTS.HEADER,
      attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
    })
  }

  const handlePrioritySupportClick = () => {
    trackEvent.current?.({
      link: buildPath(PRIORITY_SUPPORT_ROUTE, {}, { uc: '2' }),
      attributeId: EVENTS.HEADER,
      attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
    })
  }

  const isSilverOrGoldTier = getIsSilverOrGoldKfTier(user?.krisflyerTier)

  return (
    <ul className={s.supportDropdown}>
      {isSilverOrGoldTier && (
        <li>
          <ExternalLink
            className={s.item}
            href={buildPath(PRIORITY_SUPPORT_ROUTE, {}, { uc: '2' })}
            onClick={handlePrioritySupportClick}
          >
            <Icon name="user" />
            <div className={s.itemContent}>
              <span>{t('t.prioritySupport', { ns: 'common' })}</span>
              <KfTier />
            </div>
          </ExternalLink>
        </li>
      )}
      <li>
        <button onClick={handleVirtualAssistantClick} type="button" className={s.item}>
          <Icon name="chat-smile" />
          <span>{t('t.virtualAssistant', { ns: 'common' })}</span>
        </button>
      </li>
      <li>
        <Link href={HELPCENTER_LINK} passHref>
          <a className={s.item} onClick={handleHelpCenterClick}>
            <Icon name="question" />
            <span>{t('t.helpCenter', { ns: 'common' })}</span>
          </a>
        </Link>
      </li>
    </ul>
  )
}

export { SupportDropdown }
