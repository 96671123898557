import React, { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Image } from 'ui/image'
import { Variants } from 'ui/button/types'

import { Modal } from 'components/responsive-modal'
import { RichText } from 'components/rich-content/RichText'

import { useToggle } from 'lib/hooks/useToggle'

import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'
import { CAMPAIGN_TYPE, LOCAL_STORAGE_SEASONAL_CAMPAIGN } from 'lib/constants'

import s from './styles.module.scss'

const CampaignModal = () => {
  const [isOpen, { onClose, setIsOpen }] = useToggle()
  const [allowRender, { setIsOpen: setAllowRender }] = useToggle()
  const { trackEvent, campaignData } = useAppData()
  const { isMobileView } = useGlobalContext()
  const { pathname: pathName } = useRouter()
  const themeToButtonVariant: Record<string, Variants> = {
    E5FB70: 'secondary',
    '2892F4': 'secondary',
    D6F8D6: 'secondary',
    F9ECA1: 'primary',
    '00644C': 'secondary',
    '000000': 'primary-neon',
    FFDCDC: 'secondary',
  }
  const theme = campaignData?.campaignMetaData?.bg_color?.slice(1)
  const TYPE_POPUP = 'POPUP'

  const ctaLink = useMemo(() => {
    // build button clickable click
    if (!allowRender || !campaignData?.content?.button?.url || !isOpen) return null
    return campaignData?.content?.button?.url
  }, [isOpen, campaignData, allowRender])

  useEffect(() => {
    // allow render if campaign is added for popup
    if (campaignData?.campaignMetaData?.displayed_as?.indexOf?.(TYPE_POPUP) > -1) {
      setAllowRender(true)
      return
    }
    setAllowRender(false)
  }, [campaignData, setAllowRender])

  useEffect(() => {
    if (!allowRender) return

    // show popup only once a day
    // if campaign is preview mode, show popup always
    if (campaignData?.campaignType === CAMPAIGN_TYPE.SEASONAL_CAMPAIGN) {
      const now = new Date()
      const today = now.toISOString().split('T')[0]
      // Check when the popup was last shown
      const lastShownDate = localStorage.getItem(LOCAL_STORAGE_SEASONAL_CAMPAIGN)
      if (!campaignData?.isPreviewMode && lastShownDate === today) return
      setIsOpen(true)
      localStorage.setItem(LOCAL_STORAGE_SEASONAL_CAMPAIGN, today)
    }
  }, [campaignData, setIsOpen, pathName, allowRender])

  useEffect(() => {
    if (!isOpen || !allowRender) return

    trackEvent?.current?.({
      attributeId: EVENTS.SEASONAL_CAMPAIGN.MODAL,
      attributeType: EVENTS.ATTRIBUTES_TYPE.POPUP,
      eventType: EVENTS.TYPE.EXPOSURE,
      attributeValue: {
        campaignId: campaignData?.campaignId,
      },
    })
  }, [isOpen, trackEvent, campaignData?.campaignId, allowRender])

  const onModalClose = useCallback(() => {
    trackEvent?.current?.({
      attributeId: EVENTS.SEASONAL_CAMPAIGN.MODAL_CLOSE,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
      eventType: EVENTS.TYPE.CLICK,
      attributeValue: {
        campaignId: campaignData?.campaignId,
      },
    })
    onClose()
  }, [onClose, trackEvent, campaignData?.campaignId])

  if (!allowRender) return null

  const hasImage =
    campaignData?.campaignMetaData?.display_image_on?.indexOf?.(TYPE_POPUP) !== -1 && campaignData?.mediaData

  return (
    <Modal
      open={isOpen}
      topOffset={isMobileView ? '150px' : '200px'}
      hideCloseButton
      onEscKeyPress={onModalClose}
      fullScreen={isMobileView}
      contentClassName={isMobileView ? 'p-4' : undefined}
    >
      <div className={cn(s.campaignModal, s[`_theme_${theme}`])}>
        {hasImage && (
          <Image
            src={campaignData?.mediaData}
            size="medium"
            alt={`campaign-${campaignData?.campaignId}`}
            aspectRatio={2 / 1}
          />
        )}
        <div className={s.spacingContainer}>
          <div className={cn('header-3', { [s.headerSpacing]: !hasImage })}>
            <RichText text={campaignData?.content?.title} className={s.ritchText} />
          </div>
          <div className="body-md">
            <RichText text={campaignData?.content?.description} className={s.ritchText} />
          </div>

          <div className={s.closeButton}>
            <Button
              iconName="delete-x"
              shape="circle"
              variant="tertiary-outline"
              size={isMobileView ? 'medium' : 'large'}
              onClick={onModalClose}
            ></Button>
          </div>
          {campaignData?.content?.button && (
            <Button
              variant={themeToButtonVariant[theme || '']}
              className="mt-2"
              fluid
              onClick={() => {
                trackEvent?.current?.({
                  attributeId: EVENTS.SEASONAL_CAMPAIGN.MODAL,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
                  attributeValue: {
                    campaignId: campaignData?.campaignId,
                    link: campaignData?.content?.button?.url,
                  },
                })
                window.open(ctaLink, '_blank')
                onClose()
              }}
            >
              {campaignData?.content?.button?.label}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export { CampaignModal }
