// import ActivityDateOutline from './activity-date-outline.svg'
import AirBalloon from './air-balloon.svg'
import Airplane from './airplane.svg'
import AirplaneLanding from './airplane-landing.svg'
import AirplaneTitleNew from './airplane-tilt-new.svg'
import AirplaneTilt from './airplane-tilt.svg'
import AirplaneTiltFill from './airplane-tilt-fill.svg'
// import ArrowDown from './arrow-down.svg'
import ArrowDownUp from './arrows-down-up.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowRight from './arrow-right.svg'
// import ArrowsOut from './arrows-out.svg'
import ArrowUp from './arrow-up.svg'
import AttractionsNew from './attractions-new.svg'
import Attractions from './attractions.svg'
import BabyNew from './baby-new.svg'
import Baby from './baby.svg'
import Backpack from './backpack.svg'
import Bed from './bed.svg'
import BeerJug from './beer-jug.svg'
import Bicycle from './bicycle.svg'
import Binocular from './binocular.svg'
import BirdNew from './bird-new.svg'
import Bird from './bird.svg'
import BoatSmall from './boat-small.svg'
import BoatNew from './boat-new.svg'
import Boat from './boat.svg'
import BookingDateOutline from './booking-date-outline.svg'
// import BookingsOutline from './bookings-outline.svg'
import Books from './books.svg'
import Bowling from './bowling.svg'
import BriefcaseNew from './briefcase-new.svg'
import Briefcase from './briefcase.svg'
import BuggyCar from './buggy-car.svg'
import BusOpenTop from './bus-open-top.svg'
import Bus from './bus.svg'
import CableCar from './cable-car.svg'
import CalendarCancellation from './calendar-cancellation.svg'
import CalendarCancellationOutline from './calendar-cancellation-outline.svg'
import CalendarCheckOutline from './calendar-check-outline.svg'
import CalendarOutline from './calendar-outline.svg'
import Camera from './camera.svg'
import Camping from './camping.svg'
import CancellationPolicyOutline from './cancellation-policy-outline.svg'
import Cancellation from './cancellation.svg'
import Canyon from './canyon.svg'
import CarNew from './car-new.svg'
import CarSmall from './car-small.svg'
import Car from './car.svg'
import CardStar from './card-star.svg'
import CaretDoubleUp from './caret-double-up.svg'
import CaretUp from './caret-up.svg'
import CaretDown from './caret-down.svg'
import CaretDownCircle from './caret-down-circle.svg'
import CaretRight from './caret-right.svg'
import CaretLeft from './caret-left.svg'
import Chats from './chats.svg'
import ChatSmile from './chat-smile.svg'
import ChatTearDropText from './chat-teardrop-text.svg'
import Check from './check.svg'
import CircleWavyCheck from './circle-wavy-check.svg'
import CircleWavyPercent from './circle-wavy-percent.svg'
import CheckCircle from './check-circle.svg'
import CheckCircleOutline from './check-circle-outline.svg'
import CheckSquareOffset from './check-square-offset.svg'
import CircularProgress from './circular-progress.svg'
import City from './city.svg'
// import ClockClockwise from './clock-clockwise.svg'
import Clock from './clock.svg'
import ColorPlateNew from './color-plate-new.svg'
import ColorPlate from './color-plate.svg'
// import Compass from './compass.svg'
import CookingHat from './cooking-hat.svg'
// import Copy from './copy.svg'
import CopySimple from './copy-simple.svg'
import CoinVertical from './coin-vertical.svg'
// import Covid19SafeOutline from './covid-19-safe-outline.svg'
import CreditCardOutline from './credit-card-outline.svg'
import CruiseShip from './cruise-ship.svg'
import CurrencyCircle from './currency-circle.svg'
import CurrencyConverter from './currency-converter.svg'
import Curtain from './curtain.svg'
import DeleteX from './delete-x.svg'
import Desert from './desert.svg'
import DeviceMobile from './device-mobile.svg'
import DotsThree from './dots-three.svg'
import DotsSix from './dots-six.svg'
import DownloadSimple from './download-simple.svg'
import Edit from './edit.svg'
import Elderly from './elderly.svg'
import Envelope from './envelop.svg'
import EyeSlash from './eye-slash.svg'
import Eye from './eye.svg'
import FaceLaugh from './face-laugh.svg'
// import FaceMask from './face-mask.svg'
import FaceStar from './face-star.svg'
import FacebookRound from './facebook-round.svg'
import Ferry from './ferry.svg'
import Festival from './festival.svg'
// import FilterSlider from './filter-slider.svg'
// import FilterSliderOutline from './filter-slider-outline.svg'
import Fire from './fire.svg'
import Fireworks from './fireworks.svg'
import Fish from './fish.svg'
import Flag from './flag.svg'
import FlowerLotusHand from './flower-lotus-hand.svg'
import FlowerLotusNew from './flower-lotus-new.svg'
import FlowerLotus from './flower-lotus.svg'
import ForkKnifeNew from './fork-knife-new.svg'
import ForkKnife from './fork-knife.svg'
import ForkSpoon from './fork-spoon.svg'
import FortNew from './fort-new.svg'
import Fort from './fort.svg'
// import Funnel from './funnel.svg'
import GemNew from './gem-new.svg'
import Gem from './gem.svg'
import Gift from './gift.svg'
import Glamping from './glamping.svg'
import GlobeSimple from './globe-simple.svg'
import Globe from './globe.svg'
import Golf from './golf.svg'
import Gym from './gym.svg'
import HandHoldingPhone from './hand-holding-phone.svg'
// import Headset from './headset.svg'
import HandPointing from './hand-pointing.svg'
import HandShake from './handshake.svg'
import HandWaving from './handwaving.svg'
import Hash from './hash.svg'
import Headphone from './headphone.svg'
import HeartFill from './heart-fill.svg'
import Heart from './heart.svg'
import Helicopter from './helicopter.svg'
// import Home from './home.svg'
import Horse from './horse.svg'
// import HourglassMedium from './hourglass-medium.svg'
import IdentificationCardOutline from './identification-card-outline.svg'
import ImageOutline from './image-outline.svg'
import ImagesOutline from './images-outline.svg'
import Incognito from './incognito.svg'
import Indoor from './indoor.svg'
import InfoOutline from './info-outline.svg'
import Info from './info.svg'
import Information from './information.svg'
import InformationOutline from './information-outline.svg'
import InstagramLogo from './instagram-logo.svg'
import InstagramRound from './instagram-round.svg'
import JetSki from './jet-ski.svg'
import Kayak from './kayak.svg'
import KrisFlyerOutline from './krisflyer-outline.svg'
import Landmark from './landmark.svg'
import Language from './language.svg'
import Lightning from './lightning.svg'
import Link from './link.svg'
import LinkedinRound from './linkedin-round.svg'
import List from './list.svg'
// import Login from './log-in.svg'
import Lounge from './lounge.svg'
import MagicWand from './magic-wand.svg'
import MargnifyingGlass from './magnifying-glass.svg'
import MapPinFilled from './map-pin-filled.svg'
import MapPinLine from './map-pin-line.svg'
import MapPin from './map-pin.svg'
import MapTrifoldPin from './map-trifold-pin.svg'
import MapTrifold from './map-trifold.svg'
import Meditation from './meditation.svg'
import Megaphone from './megaphone.svg'
// import MegaphoneSimple from './megaphone-simple.svg'
// import MinusCircle from './minus-circle.svg'
import Minus from './minus.svg'
import Motorbike from './motorbike.svg'
import MountainMotorbike from './mountain-motorbike.svg'
import Mountain from './mountain.svg'
import MultiDay from './multi-day.svg'
import Museum from './museum.svg'
import Music from './music.svg'
import Nature from './nature.svg'
import NewsPaper from './newspaper.svg'
import Outdoor from './outdoor.svg'
import Paintbrush from './paintbrush.svg'
// import PaperclipOutline from './paperclip-outline.svg'
import Parachute from './parachute.svg'
import Parasail from './parasail.svg'
import PelagoNew from './pelago-new.svg'
import Pelago from './pelago.svg'
import PencilSimple from './pencil-simple.svg'
import PersonDancing from './person-dancing.svg'
import PersonFlying from './person-flying.svg'
import PersonSkiing from './person-skiing.svg'
import PersonZiplining from './person-ziplining.svg'
import CircleWeavyFilledPercent from './circle-wavy-filled-percent.svg'
import CircleWeavyFilledPlane from './circle-wavy-filled-plane.svg'
import Phone from './phone.svg'
import PlusCircle from './plus-circle.svg'
import Plus from './plus.svg'
import Pottery from './pottery.svg'
import Pray from './pray.svg'
import PrivateTour from './private-tour.svg'
import Processing from './processing.svg'
import Question from './question.svg'
import Raft from './raft.svg'
// import Reset from './reset.svg'
// import RewardOutline from './reward-outline.svg'
import RocketLaunch from './rocket-launch.svg'
import Sailboat from './sailboat.svg'
import ScubaDive from './scuba-dive.svg'
// import SecurityOutline from './security-outline.svg'
import Settings from './settings.svg'
// import ShoppingCart from './shopping-cart.svg'
import Share from './share.svg'
import ShareNetwork from './share-network.svg'
import ShootingStar from './shooting-star.svg'
import Signout from './sign-out.svg'
import ShieldCheck from './shield-check.svg'
import ShieldWarning from './shield-warning.svg'
import ShoesSnow from './shoes-snow.svg'
import Shoes from './shoes.svg'
import ShoppingBag from './shopping-bag.svg'
import Sliders from './sliders.svg'
import SoccerBall from './soccer-ball.svg'
import SoftDrink from './soft-drink.svg'
// import SortAscending from './sort-ascending.svg'
import SparkleFilled from './sparkle-filled.svg'
import SparkleTwo from './sparkle-two.svg'
import Sparkle from './sparkle.svg'
import SpeedBoat from './speedboat.svg'
import SquareFour from './square-four.svg'
// import Star from './star.svg'
import Success from './success.svg'
import SunNew from './sun-new.svg'
import Sun from './sun.svg'
import SurfBoard from './surf-board.svg'
import TagFilled from './tag-filled.svg'
import TagNew from './tag-new.svg'
import Tag from './tag.svg'
import ThemePark from './theme-park.svg'
// import Target from './target.svg'
// import ThumbsDown from './thumbs-down.svg'
import ThumbsUp from './thumbs-up.svg'
import Ticket from './ticket.svg'
import TikTokRound from './tiktok-round.svg'
import TowelHanging from './towel-hanging.svg'
import TowerTilt from './tower-tilt.svg'
import TrainSide from './train-side.svg'
import TrainSimpleNew from './train-simple-new.svg'
import TrainSimple from './train-simple.svg'
import Train from './train.svg'
import Translate from './translate.svg'
import TranslateFilled from './translate-filled.svg'
// import TransportationOutline from './transportation-outline.svg'
import Trash from './trash.svg'
import TrashSimple from './trash-simple.svg'
import TreeNew from './tree-new.svg'
import Tree from './tree.svg'
import TrendUp from './trend-up.svg'
import UserCircle from './user-circle.svg'
// import Upload from './upload.svg'
// import UserPlus from './user-plus.svg'
import User from './user.svg'
import Users from './users.svg'
import UserFour from './user-four.svg'
import UserSwitch from './user-switch.svg'
import Warning from './warning.svg'
import WaterPark from './water-park.svg'
import WaterSport from './water-sport.svg'
import WifiHighNew from './wifi-high-new.svg'
import WifiHigh from './wifi-high.svg'
import WifiPhone from './wifi-phone.svg'
import WineGlass from './wine-glass.svg'
// import WarningOutline from './warning-outline.svg'
import XCircle from './x-circle.svg'
import YogaMat from './yoga-mat.svg'

const iconMappings = {
  // 'activity-date-outline': ActivityDateOutline,
  'air-balloon': AirBalloon,
  airplane: Airplane,
  'airplane-landing': AirplaneLanding,
  'airplane-tilt-new': AirplaneTitleNew,
  'airplane-tilt': AirplaneTilt,
  'airplane-tilt-fill': AirplaneTiltFill,
  // 'arrow-down': ArrowDown,
  'arrow-down-up': ArrowDownUp,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  // 'arrows-out': ArrowsOut,
  'attractions-new': AttractionsNew,
  attractions: Attractions,
  'baby-new': BabyNew,
  baby: Baby,
  backpack: Backpack,
  bed: Bed,
  'beer-jug': BeerJug,
  bicycle: Bicycle,
  binocular: Binocular,
  'bird-new': BirdNew,
  bird: Bird,
  'boat-small': BoatSmall,
  'boat-new': BoatNew,
  boat: Boat,
  'booking-date-outline': BookingDateOutline,
  // 'bookings-outline': BookingsOutline,
  books: Books,
  bowling: Bowling,
  'briefcase-new': BriefcaseNew,
  briefcase: Briefcase,
  'buggy-car': BuggyCar,
  'bus-open-top': BusOpenTop,
  bus: Bus,
  'cable-car': CableCar,
  'calendar-cancellation': CalendarCancellation,
  'calendar-cancellation-outline': CalendarCancellationOutline,
  'calendar-check-outline': CalendarCheckOutline,
  'calendar-outline': CalendarOutline,
  camera: Camera,
  camping: Camping,
  'cancellation-policy-outline': CancellationPolicyOutline,
  cancellation: Cancellation,
  canyon: Canyon,
  'car-new': CarNew,
  'car-small': CarSmall,
  car: Car,
  'card-star': CardStar,
  'caret-double-up': CaretDoubleUp,
  'caret-down-circle': CaretDownCircle,
  'caret-down': CaretDown,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  'chat-smile': ChatSmile,
  'chat-teardrop-text': ChatTearDropText,
  chats: Chats,
  'check-circle-outline': CheckCircleOutline,
  'check-circle': CheckCircle,
  'check-square-offset': CheckSquareOffset,
  check: Check,
  'circle-wavy-check': CircleWavyCheck,
  'circle-wavy-filled-percent': CircleWeavyFilledPercent,
  'circle-wavy-filled-plane': CircleWeavyFilledPlane,
  'circle-wavy-percent': CircleWavyPercent,
  'circular-progress': CircularProgress,
  city: City,
  // 'clock-clockwise': ClockClockwise,
  clock: Clock,
  'color-plate-new': ColorPlateNew,
  'color-plate': ColorPlate,
  // compass: Compass,
  'cooking-hat': CookingHat,
  'copy-simple': CopySimple,
  'coin-vertical': CoinVertical,
  // copy: Copy,
  // 'covid-19-safe-outline': Covid19SafeOutline,
  'credit-card-outline': CreditCardOutline,
  'cruise-ship': CruiseShip,
  'currency-circle': CurrencyCircle,
  'currency-converter': CurrencyConverter,
  curtain: Curtain,
  'delete-x': DeleteX,
  desert: Desert,
  'device-mobile': DeviceMobile,
  'dots-three': DotsThree,
  'dots-six': DotsSix,
  'download-simple': DownloadSimple,
  edit: Edit,
  elderly: Elderly,
  envelope: Envelope,
  'eye-slash': EyeSlash,
  eye: Eye,
  'face-laugh': FaceLaugh,
  // 'face-mask': FaceMask,
  'face-star': FaceStar,
  'facebook-round': FacebookRound,
  ferry: Ferry,
  festival: Festival,
  // 'filter-slider': FilterSlider,
  // 'filter-slider-outline': FilterSliderOutline,
  fire: Fire,
  fireworks: Fireworks,
  fish: Fish,
  flag: Flag,
  'flower-lotus-hand': FlowerLotusHand,
  'flower-lotus-new': FlowerLotusNew,
  'flower-lotus': FlowerLotus,
  'fork-knife-new': ForkKnifeNew,
  'fork-knife': ForkKnife,
  'fork-spoon': ForkSpoon,
  'fort-new': FortNew,
  fort: Fort,
  // funnel: Funnel,
  'gem-new': GemNew,
  gem: Gem,
  gift: Gift,
  glamping: Glamping,
  'globe-simple': GlobeSimple,
  globe: Globe,
  golf: Golf,
  gym: Gym,
  'hand-holding-phone': HandHoldingPhone,
  // headset: Headset,
  'hand-pointing': HandPointing,
  handshake: HandShake,
  handwaving: HandWaving,
  hash: Hash,
  headphone: Headphone,
  'heart-fill': HeartFill,
  heart: Heart,
  helicopter: Helicopter,
  // home: Home,
  horse: Horse,
  // 'hourglass-medium': HourglassMedium,
  'identification-card-outline': IdentificationCardOutline,
  'image-outline': ImageOutline,
  'images-outline': ImagesOutline,
  incognito: Incognito,
  indoor: Indoor,
  'info-outline': InfoOutline,
  'information-outline': InformationOutline,
  information: Information,
  info: Info,
  'instagram-logo': InstagramLogo,
  'instagram-round': InstagramRound,
  'jet-ski': JetSki,
  kayak: Kayak,
  'krisflyer-outline': KrisFlyerOutline,
  landmark: Landmark,
  language: Language,
  lightning: Lightning,
  link: Link,
  'linkedin-round': LinkedinRound,
  list: List,
  // 'log-in': Login,
  lounge: Lounge,
  'magic-wand': MagicWand,
  'magnifying-glass': MargnifyingGlass,
  'map-pin-filled': MapPinFilled,
  'map-pin-line': MapPinLine,
  'map-pin': MapPin,
  'map-trifold-pin': MapTrifoldPin,
  'map-trifold': MapTrifold,
  meditation: Meditation,
  // 'megaphone-simple': MegaphoneSimple,
  megaphone: Megaphone,
  // 'minus-circle': MinusCircle,
  minus: Minus,
  motorbike: Motorbike,
  'mountain-motorbike': MountainMotorbike,
  mountain: Mountain,
  'multi-day': MultiDay,
  museum: Museum,
  music: Music,
  nature: Nature,
  newspaper: NewsPaper,
  outdoor: Outdoor,
  paintbrush: Paintbrush,
  // 'paperclip-outline': PaperclipOutline,
  parachute: Parachute,
  parasail: Parasail,
  pelago: Pelago,
  'pelago-new': PelagoNew,
  'pencil-simple': PencilSimple,
  'person-dancing': PersonDancing,
  'person-flying': PersonFlying,
  'person-skiing': PersonSkiing,
  'person-ziplining': PersonZiplining,
  phone: Phone,
  'plus-circle': PlusCircle,
  plus: Plus,
  pottery: Pottery,
  pray: Pray,
  'private-tour': PrivateTour,
  processing: Processing,
  question: Question,
  raft: Raft,
  // reset: Reset,
  // 'reward-outline': RewardOutline,
  'rocket-launch': RocketLaunch,
  sailboat: Sailboat,
  'scuba-dive': ScubaDive,
  // 'security-outline': SecurityOutline,
  settings: Settings,
  'share-network': ShareNetwork,
  share: Share,
  'shield-check': ShieldCheck,
  'shield-warning': ShieldWarning,
  'shoes-snow': ShoesSnow,
  shoes: Shoes,
  'shooting-star': ShootingStar,
  'shopping-bag': ShoppingBag,
  'sign-out': Signout,
  sliders: Sliders,
  'soccer-ball': SoccerBall,
  'soft-drink': SoftDrink,
  'sparkle-filled': SparkleFilled,
  'sparkle-two': SparkleTwo,
  sparkle: Sparkle,
  speedboat: SpeedBoat,
  'square-four': SquareFour,
  // 'shopping-cart': ShoppingCart,
  // 'sort-ascending': SortAscending,
  success: Success,
  'sun-new': SunNew,
  sun: Sun,
  'surf-board': SurfBoard,
  // star: Star,
  tagFilled: TagFilled,
  'tag-new': TagNew,
  tag: Tag,
  'theme-park': ThemePark,
  // target: Target,
  // 'thumbs-down': ThumbsDown,
  'thumbs-up': ThumbsUp,
  ticket: Ticket,
  'tiktok-round': TikTokRound,
  'towel-hanging': TowelHanging,
  'tower-tilt': TowerTilt,
  'train-side': TrainSide,
  'train-simple-new': TrainSimpleNew,
  'train-simple': TrainSimple,
  train: Train,
  translate: Translate,
  'translate-filled': TranslateFilled,
  // 'transportation-outline': TransportationOutline,
  trash: Trash,
  'trash-simple': TrashSimple,
  'trend-up': TrendUp,
  'tree-new': TreeNew,
  tree: Tree,
  'user-circle': UserCircle,
  // 'user-plus': UserPlus,
  user: User,
  users: Users,
  'user-four': UserFour,
  'user-switch': UserSwitch,
  // 'warning-outline': WarningOutline,
  warning: Warning,
  'water-park': WaterPark,
  'water-sport': WaterSport,
  'wifi-high-new': WifiHighNew,
  'wifi-high': WifiHigh,
  'wifi-phone': WifiPhone,
  'wine-glass': WineGlass,
  'x-circle': XCircle,
  'yoga-mat': YogaMat,
}

export type IconNames = keyof typeof iconMappings

export { iconMappings }
