import { useEffect } from 'react'

import { Icon } from 'ui/icon'
import { Button } from 'ui/button'

import { ExternalLink } from 'components/external-link'

import useAuth from 'lib/hooks/useAuth'
import useTranslation from 'lib/hooks/useTranslation'
import useRouteMatch from 'lib/hooks/useRouteMatch'

import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'
import { MOBILE_APP_DOWNLOAD_LINK } from 'lib/constants'
import { REFERRAL_SIGNUP_ROUTE } from 'lib/constants/routes'

import s from './styles.module.scss'

type MobileMenuAppBannerProps = {
  onClick?: () => void
  menuActive: boolean
}

const MobileMenuAppBanner = ({ menuActive, onClick }: MobileMenuAppBannerProps) => {
  const { t } = useTranslation()
  const { isLoggedIn, authModal } = useAuth()
  const { trackEvent } = useAppData()
  const {
    globalArgs: { appTouchpointsData },
  } = useGlobalContext()
  const { header } = appTouchpointsData
  const isReferralSignupPage = Boolean(useRouteMatch(REFERRAL_SIGNUP_ROUTE))
  const hideAuthEntryPoints = isLoggedIn || isReferralSignupPage

  useEffect(() => {
    if (menuActive) {
      trackEvent.current({
        attributeId: EVENTS.MOBILE_MENU_MOBILE_APP,
        attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
        eventType: EVENTS.TYPE.EXPOSURE,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuActive, trackEvent.current])

  const onClickDownloadApp = () => {
    trackEvent.current({
      attributeId: EVENTS.MOBILE_MENU_MOBILE_APP,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
      eventType: EVENTS.TYPE.CLICK,
    })
  }

  return (
    <div className={s.mobileMenuAppBanner}>
      <ExternalLink href={MOBILE_APP_DOWNLOAD_LINK}>
        <div onClick={onClickDownloadApp} className={s.appBanner}>
          <Icon name="device-mobile" size="small" />
          <p className={s.label} dangerouslySetInnerHTML={{ __html: header }}></p>
          <span className={s.getApp}>{t('t.getApp', { ns: 'common' })}</span>
        </div>
      </ExternalLink>
      {!hideAuthEntryPoints && (
        <div className={s.authBtnsContainer}>
          <div className={s.authBtns}>
            <Button
              variant="primary"
              fluid
              onClick={() => {
                trackEvent.current?.({
                  attributeId: EVENTS.FROM_SETTINGS_SIGNUP,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })
                authModal.open({ screen: 'signup' })
                onClick?.()
              }}
            >
              {t('t.signUp')}
            </Button>
            <Button
              variant="secondary"
              fluid
              onClick={() => {
                trackEvent.current?.({
                  attributeId: EVENTS.FROM_SETTINGS_LOGIN,
                  attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                })
                authModal.open({ screen: 'login' })
                onClick?.()
              }}
            >
              {t('t.logIn')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export { MobileMenuAppBanner }
